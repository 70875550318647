import {isEventExternalRegistration} from '@wix/wix-events-commons-statics'
import {isMobile} from '../../../../commons/selectors/environment'
import {
  getButtonBorderRadius,
  getButtonHeight,
  getOneButtonWidth,
  getRoundedButtonBorderRadius,
  getRsvpTitleText,
  isHollowButton,
  isResponsive,
  isRoundedButton,
} from '../../../../commons/selectors/settings'
import {getRSVPButtonTextForSingleEvent} from '../../../selectors/events'
import {
  getComponentConfig,
  getRsvpButtonMargin,
  isOnlyButtonVisible,
  isOnImageLayout,
  isRsvpLabelVisible,
} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {withMargin} from '../../hoc/with-margin'
import {connect} from '../../runtime-context/connect'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'

interface RsvpButtonOwnProps {
  event: wix.events.Event
}

interface RsvpButtonStateProps {
  title: string
  text: string
  roundedButton: boolean
  hollowButton: boolean
  width: number
  height: number
  borderRadius: number
  roundedBorderRadius: number
  onImage: boolean
  isMobile: boolean
  navigateToPage: Function
  responsiveButton: boolean
}

export interface RsvpButtonProps extends RsvpButtonOwnProps, RsvpButtonStateProps {}

const mapState = ({state, actions}: AppProps, {event}: RsvpButtonOwnProps): RsvpButtonStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    title: isRsvpLabelVisible(state) ? getRsvpTitleText(componentConfig) : null,
    text: getRSVPButtonTextForSingleEvent(state, event),
    roundedButton: isRoundedButton(componentConfig),
    hollowButton: isHollowButton(componentConfig),
    width: isMobile(state) ? undefined : getOneButtonWidth(componentConfig),
    height: isMobile(state) ? undefined : getButtonHeight(componentConfig),
    borderRadius: getButtonBorderRadius(componentConfig),
    roundedBorderRadius: getRoundedButtonBorderRadius(componentConfig),
    onImage: isOnImageLayout(state),
    isMobile: isMobile(state),
    responsiveButton: isResponsive(componentConfig) && isOnlyButtonVisible(state),
    navigateToPage: () => {
      if (isEventExternalRegistration(event)) {
        window.open(event.registration.external.registration, '_blank')
      }
      actions.navigateToPage(event, state)
    },
  }
}

const getMargin = ({state}: AppProps) => getRsvpButtonMargin(state)

export const RsvpButton = withMargin(
  connect<RsvpButtonOwnProps, RsvpButtonStateProps>(mapState)(RsvpButtonPresentation),
  getMargin,
)
