import {IDimensions} from '@wix/native-components-infra/dist/src/types/types'
import {hasGuestsGoing} from '@wix/wix-events-commons-statics'
import {isRsvp} from '@wix/wix-events-commons-statics'
import {IMAGE_RATIO} from '../../commons/constants/settings'
import {isEditor, isFullWidth} from '../../commons/selectors/environment'
import {
  getImageRatio,
  isCardsLayout,
  isListAdditionalInfoEnabled,
  isListDateEnabled,
  isListDescriptionEnabled,
  isListFullDateEnabled,
  isListImageEnabled,
  isListLayout,
  isListLocationEnabled,
  isListMainTitleEnabled,
  isListMembersElementEnabled,
  isListSocialShareEnabled,
  isListVenueNameEnabled,
  isResponsive,
} from '../../commons/selectors/settings'
import {isFirstEventCreated} from '../../commons/selectors/site-settings'
import {MEDIUM_WIDTH, MOBILE_DESKTOP_BREAKPOINT, MOBILE_WIDTH} from '../components/list-layout/constants'
import {State} from '../types/state'
import {getEvents, getEventById} from './events'
import {getComponentConfig} from './settings'

// TODO: check - seems that these are duplicate selectors

export const isNarrow = (state: State, dimensions: IDimensions) => {
  if (isResponsive(getComponentConfig(state))) {
    return dimensions.width <= MOBILE_DESKTOP_BREAKPOINT
  }
  return !isFullWidth(state, dimensions) && dimensions.width < MOBILE_WIDTH
}

export const isMainTitleVisible = (state: State) => isListMainTitleEnabled(getComponentConfig(state))

export const isDateVisible = (state: State) => isListDateEnabled(getComponentConfig(state))

export const isVenueVisible = (state: State, dimensions: IDimensions) => {
  const componentConfig = getComponentConfig(state)
  const shouldVenueShow = isListVenueNameEnabled(componentConfig)

  if (isListLayout(componentConfig)) {
    return shouldVenueShow && (dimensions.width > MEDIUM_WIDTH || isFullWidth(state, dimensions))
  }

  return shouldVenueShow
}

export const isFullDateVisible = (state: State) => isListFullDateEnabled(getComponentConfig(state))

export const isLocationVisible = (state: State) => isListLocationEnabled(getComponentConfig(state))

export const isDescriptionVisible = (state: State) => isListDescriptionEnabled(getComponentConfig(state))

export const isSocialShareVisible = (state: State) => isListSocialShareEnabled(getComponentConfig(state))

export const isImageVisible = (state: State) => isListImageEnabled(getComponentConfig(state))

export const isMembersEnabled = (state: State) =>
  state.membersEnabled && (isListMembersElementEnabled(getComponentConfig(state)) || isMembersVisibleInEditor(state))

export const isMembersVisible = (state: State, event: ExtendedEvent) =>
  isMembersEnabled(state) && (hasGuestsGoing(event.totalGuests, isRsvp(event)) || isMembersVisibleInEditor(state))

export const isMembersVisibleInEditor = (state: State) =>
  isEditor(state) && isListMembersElementEnabled(getComponentConfig(state))

export const isAdditionalComponentsHidden = (state: State, eventId?: string) => {
  const descriptionVisible = eventId
    ? isDescriptionVisible(state) && !!getEventById(state, eventId).description
    : isDescriptionVisible(state)

  return (
    !isListAdditionalInfoEnabled(getComponentConfig(state)) ||
    (!isFullDateVisible(state) && !isLocationVisible(state) && !descriptionVisible && !isSocialShareVisible(state))
  )
}

export const isMobileSquareImage = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isCardsLayout(componentConfig) && getImageRatio(componentConfig) === IMAGE_RATIO['1:1']
}

export const getImageMargins = (state: State, dimensions: IDimensions, rtl: boolean) =>
  isNarrow(state, dimensions) ? {marginBottom: 20} : {[rtl ? 'marginLeft' : 'marginRight']: 22}

export const isEmptyStateNotificationVisible = (state: State) =>
  isFirstEventCreated(state.siteSettings) && !getEvents(state).length
