import {IWixWindowViewMode} from 'native-components-infra/dist/es/src/types/types'
import {IHostProps} from 'native-components-infra/dist/src/types/types'
import {widgetLoaded} from '../../actions/layout'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {AppLoaded as AppLoadedUnconnected} from './app-loaded'

export interface AppLoadedProps extends AppLoadedOwnProps {
  appLoaded: () => void
}

export interface AppLoadedOwnProps {
  appLoaded643: Function
  widgetLoaded: typeof widgetLoaded
  viewMode: IWixWindowViewMode
  isHomePage: boolean
  host: IHostProps
}

const mapRuntimeToProps = ({actions}: AppProps) => ({
  appLoaded: actions.appLoaded,
})

export const AppLoaded = connect<AppLoadedOwnProps>(mapRuntimeToProps)(AppLoadedUnconnected)
