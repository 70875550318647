import {isEventExternalRegistration} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {getListButtonStyle} from '../../../commons/selectors/settings'
import {getRSVPButtonTextForEventListItem} from '../../selectors/events'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'

export interface RsvpButtonProps extends ButtonOwnProps, ButtonStateProps {}

interface ButtonOwnProps {
  event: wix.events.Event
  dataHook?: string
  fullWidth?: boolean
  minWidth?: number
  maxWidth?: number
  additionalStyle?: React.CSSProperties
}

interface ButtonStateProps {
  text: string
  style: number
  navigateToPage: Function
}

const mapRuntime = ({state, actions}: AppProps, {event}: ButtonOwnProps): ButtonStateProps => ({
  navigateToPage: () => {
    if (isEventExternalRegistration(event)) {
      window.open(event.registration.external.registration, '_blank')
    }
    actions.navigateToPage(event, state)
  },
  text: getRSVPButtonTextForEventListItem(state, event),
  style: getListButtonStyle(getComponentConfig(state)),
})

export const RsvpButton = connect<ButtonOwnProps, ButtonStateProps>(mapRuntime)(RsvpButtonPresentation)
